.loader {
  background: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    opacity: 0.8;
}
.loader-image {
  width: 90px;
}