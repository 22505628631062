
@import './assets/css/sass/variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.font-size-18 {
  font-size: 18px !important;
}
.padding-t15 {
  padding-top: 15px;
}
.padding-b15 {
  padding-bottom: 15px;
}
.margin-t20 {
  margin-top: 20px;
}
.margin-b8 {
  margin-bottom: 8px;
}
.margin-b20 {
  margin-bottom: 20px;
}

.margin-b35 {
  margin-bottom: 35px;
}

.app-container {
  height:100vh;
  overflow-x:hidden;
  background: url('./assets/images/sidemenu.webp');

  .layout {
    position: relative;
    top: 0;
    left: 0;
    transition: all 400ms ease-in-out;
    border: none;
    height: 100%;
  }

  .layout.sidebar-active {
    transition: all 400ms ease-in-out;
    height: 100%;

    @media (max-width: 1025px) {
      top: 90px;
      left: 75%;
      box-shadow: 0 6px 28px 8px rgba(0, 0, 0, 0.2);
      height: 85%;
      border: inherit;
      border-radius: 30px;
    }

  }
}
.app-container.sidebar-active {
  overflow: hidden;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.25rem;
}
.head-title {
  font-size:32px;
  margin-bottom:20px;
  font-weight: 500;
}
.custom-label {
  font-size: 16pt;
  width: 100%;
  margin-bottom: 8px;
}
.input-wrapper {
  position: relative;
  margin-bottom: 30px ;

  .input-field {
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 1px solid $color-black;
    outline: none;
    padding-left: 0;
    padding-bottom: 10px;

    &:focus {
      outline: none;
      box-shadow: none;
      border-bottom: 3px solid $color-blue-btn;
    }
  }
  label {
    margin-bottom: 0;
  }


  &:focus-within {
    label {
      color: $color-sky-blue;
    }
  }
}

.bottom-box {
  background: transparent;
  border: none;
}

.back-arrow {
  height: 28.13pt;
  width: 24pt;
}
.logo {
  text-align: center;
  padding-top: 40px;
}
.inner-container {
  width: 80%;
  margin: 0 auto;
  height: 100%;
}
.main-container {
  width: 100%;
  height: 100vh;
  background: url(../src/assets/images/highDimension.webp) no-repeat center fixed;
  background-size: cover;
}

.btn-green {
  background-color: $success-color-green;
  width: 100%;
  min-height: 56px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border: none;
  border-radius:8px;

  &:focus, &:active, &:hover {
    background-color: $success-color-green;
    outline: none !important;
    border: none;
    box-shadow: none !important;
    font-weight:300;
  }

  @media (min-width: 425px) {
    font-size:20px
  }

  svg {
    font-size: 24px
  }
}

.btn-blue {
  background-color: $color-sky-blue;
  width: 100%;
  min-height: 56px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius:8px;

  &:focus, &:active {
    background-color: $color-sky-blue;
    outline: none !important;
    border: none;
    font-weight:300;
    box-shadow: none !important;
  }

  @media (min-width: 425px) {
    font-size:20px
  }
}

.btn-white {
  background-color: white;
  color: black;
  width: 100%;
  min-height: 56px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius:8px;

  &:focus, &:active {
    background-color: white;
    outline: none !important;
    color: currentColor;
    border: none;
    font-weight:300;
    box-shadow: none !important;
  }

  @media (min-width: 425px) {
    font-size:20px
  }
}

.custom-simple-btn {
  padding: 0;
  background: none;
  border: none;
  vertical-align: unset;

  &:active, &:hover, &:focus {
    background: none ;
    outline: none !important;
    border: none;
    box-shadow: none !important;
  }
}
.custom-icon-btn {
  padding: 0;
  background: none;
  border: none;
  vertical-align: unset;
  width: 30px;
  height: 30px;
  margin-bottom: 50px;

  &:active, &:focus {
    outline: none !important;
    border: none;
    box-shadow: none !important;
    background: none;
  }

  &:hover {
    background: none;
  }

}

.link-text {
  color: #323232;
  text-decoration: none;
  text-align: center;
  padding: 0;
}

.nav-link {
  color: #323232;
}
.nav-link:focus-within {
  color: #323232;
}
.label-text {
  font-size: 16pt;
  width: 100%;
}

.password-visibilty {
  outline: none;
  position: absolute;
  top: 220px;
  float: right;
  right: 0px;
}
.back-arrow {
  width: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  color: #666766;
}


.enquiries-list {
  padding-top: 12rem;
}

.enquiries-title {
  border-radius: 0pt 0pt 16px 16px;
  width: 100%;
  position: fixed;
  z-index: 5;
  top: 86px;
  right: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 30px 10px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 540px) {
    max-height: 95px
  }
}

.no-data {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 20px;
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: 38px;
    }
}

.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.color-light-blue-bg {
  background-color: $color-sky-blue-light;

  &:hover, &:focus, &:active {
    background-color: $color-sky-blue-light !important;
  }
}
.color-light-green-bg {
  background-color: $success-color-green-light;

  &:hover, &:focus, &:active {
    background-color: $success-color-green-light !important;
  }
}
.color-black {
  color: $color-black;
}
.color-blue {
  color: $color-sky-blue;

  &:hover {
    color: $color-sky-blue;
  }
}
.color-green {
  color: $success-color-green;

  &:hover {
    color: $success-color-green;
  }
}
.gray {
  color: #625d5d;
}
.dark-gray {
  color: $color-grey;
}
.light-gray {
  color: $color-light-grey;
}
.font-lg {
  font-size: 48px;
}

.font-md {
  font-size: 24px;
}
.font-sm {
  font-size: 14px;
}
.p-20 {
  padding: 20px;
}
.p-40 {
  padding: 40px;
}
.pt-54 {
  padding-top: 54.04px;
}
.pl-55 {
  padding-left: 55px;
}
.pt-68 {
  padding-top: 68px;
}
.pt-10rem {
  padding-top: 10rem;
}

.pt-7rem {
  padding-top: 7rem;
}

.p-r16 {
  padding-right: 16.4px;
}
.flex {
  display: flex;
}
.white {
  color: white;
}
.hr {
  border: 1px solid #d9d9d9;
}

.mr-20 {
  margin-right: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-70 {
  margin-top: 70px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.fw-500 {
  font-weight: 500;
}

.opacity-70 {
  opacity: 70%;
}

.divder {
  height: 4px;
  border-top: 3px solid #d9d9d9;
}

.max-height-65{
  max-height: 65px;
}

.max-height-105 {
  max-height: 105px;
}

.overlay {
  z-index: 50;
  height: 600px;
  width: 100%;

  @media (min-width: 1025px) {
    height: 100%;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.blue-img-bg {
  background: url(./assets/images/visits.png) no-repeat;
}

.green-img-bg {
  background: url(./assets/images/views.png) no-repeat;
}

// Remove outline from chat transcript accordion button
.accordion-button:focus { box-shadow: none; }
